
<template>
    <BackofficeBase :loader_prop="loader_prop">
      <CourierCompanyMenu :courier_company_id_prop="courier_company_id" />
        
      <div class="create_user_div">       
        <router-link 
          :to="{
            path : '/backoffice/backoffice_courier_edit', 
            query : {'courier_company_id' : $route.query.id, 'courier_id' : 0}}"
            >הוסף שליח</router-link
        >
      </div>
      <div v-for="courier in data" :key="courier.id" class="item_list">
        <div>
          <p>{{ courier.name }}</p>
          <!--<p>{{ courier.username }}</p>-->
          <p>{{ courier.phone_number }}</p>
          <p>{{ courier.email }}</p>
        </div>
        <div class="item_action">
          <router-link
            :to="{
              path: '/backoffice/backoffice_courier_edit',
              query: {'courier_company_id' : $route.query.id, 'courier_id' : courier.id},
            }"
            >עריכת שליח</router-link
          >
          <br/>
          <!-- TODO ADD BACKPFFICE API-->
          <s>חבילות</s>  
          <!-- <router-link
            :to="{ path: '/backoffice/backoffice_user_packages_admin/', query: { phone_number: user.phone_number } }"
            >חבילות</router-link
          >-->
        </div>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  import CourierCompanyMenu from "./CourierCompanyMenu";
  
  export default {
    name: "BackofficeCouriers",
    components: {
      BackofficeBase,
      CourierCompanyMenu,
    },

    data() {
      return {
        loader_prop: true,
        data: [],
        courier_company_id: "",
      };
    },
    async mounted() {
      
      console.log("route id: " + this.$route.query.id);
      this.courier_company_id = this.$route.query.id;
      let backendModel = new BackendModel();
      let data = await backendModel.backendRequest(
        "/Api/service/backoffice/get_couriers",
        { id: this.courier_company_id }
      );

      this.data = data.data.data;
      console.log(this.data);
      this.loader_prop = false;
    },
  };
  </script>
  
  <style scoped>
  @import "../../assets/style.css";
  </style>
  