<template>
  <div id="nav">
    <p><b>פרטי חברת הפצה</b></p>
    <!--<p>courier_company_id_prop: {{courier_company_id_prop}}</p>-->
    <router-link :to="{path : '/backoffice/courier_company_details', query : {'id' : courier_company_id_prop}}">פרטים</router-link> | 
    <!--<router-link :to="{path : '/backoffice/backoffice_admin_get_courier_company_statistics',  query : {'id' : courier_company_id_prop}}">סטטיסטיקה</router-link> |
    <router-link :to="{path : '/backoffice/courier_company_admin', query : {'id' : courier_company_id_prop}}">ניהול נק' חלוקה</router-link> | 
    
    -->
    <router-link :to="{path : '/backoffice/couriers', query : {'id' : courier_company_id_prop}}">ניהול שליחים</router-link> 
    
    <!-- <router-link :to="{path : '/backoffice/point_delete', query : {'id' : courier_company_id_prop}}">מחיקה</router-link>  -->
  </div>
</template>

<script>
export default {
  name: 'CourierCompanyMenu',
  props : ['courier_company_id_prop'],
  components: {
  },
  data() {
    return {

    }
  },
  async mounted() {
    
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>